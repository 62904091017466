import React, { Component } from 'react';
import axios from 'axios';
import Item from './item';
import { Link, Navigate } from "react-router-dom";
import Loader from './../general/loader';

class Category extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        load: false,
        back: false
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/danik");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });
    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e.target.value);
    }


    // back = event => {
    //     event.preventDefault();
    //     this.setState({ back : true });
    // }

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head">
                            <Link to={"/"}>
                                {/* onClick={this.back} */}
                                <i  className="backward fa-solid fa-arrow-left"></i>
                            </Link>
                            
                            <select name="language"
                                value={this.props.Language}
                                onChange={this.handleChange} 
                            >
                                <option value="krd">کوردی</option>
                                <option value="ar">عربی</option>
                                <option value="en">English</option>
                                
                            </select>                        
                        <img src={this.settings.logo} alt="" />
                    </div>

                    <div className="info">
                        <button>About Us</button>
                        <h3>{this.settings.name}</h3>
                    </div>

                    <div className="social">

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook"></i></a>
                        }
                        
                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }
                        
                    </div>

                    <div className="category-list">
                        {
                            this.state.load 
                            ?
                                this.state.categories.map( category => <Item key={category.id} Data = {category} Language = {this.props.Language}></Item>)
                            : 
                                <Loader></Loader>
                        }
                    </div>

                </div>
            </>
        );
    }
}

export default Category;